
.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    /* background-color: rgba(35, 39, 61, 0.733); */
    height: 100%;
}

.container h1{
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
}

.container .faq-sections{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height:100px;
    overflow-x: scroll;
    /* background-color: rgba(202, 202, 202, 0.493); */
}

.container .faq-sections .faq-header-section{
    background-color: lightgray;
    padding: 10px;
    max-width: 180px;
    border-radius: 5px;
    margin: 10px;
    font-size: 15px;
}

.container .faq-sections .faq-header-section-selected{
    background-color: rgb(70, 70, 70);
    padding: 10px;
    max-width: 180px;
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
    font-size: 15px;

    color: white;
}

.container .faq-sections .faq-header-section-selected h2{
    color: white;
}

.container .faq-sections .faq-header-section:hover{
    background-color: gray;
    cursor: pointer;
  
}

.container .faq-content .faq-item{
    
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    align-self: center;
    width: 800px;
    color: rgb(153, 150, 150);
    font-size: 14px;
    text-align: start;
}

.container .faq-content{
    background-color: transparent;;
}

.container .faq-content .faq-item{

}