

@media screen and (max-width: 568px) {
    /* .video_detail{
        width:100dvh;
        background-color: red;
        margin:10px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex-wrap: wrap;
        overflow: auto;
        
    } */
    .campaignList-header {
        display: none !important;
    }
}

