@font-face {
  font-family: "UbuntuRegular";
  src: local("UbuntuRegular"),
   url("../../fonts/ubuntu/Ubuntu-Regular.ttf") format("truetype");
  /* font-weight: regular; */
 }

 @font-face {
  font-family: "Raleway";
  src: local("Raleway"),
   url("../../fonts/raleway/Raleway-Regular.ttf") format("truetype");
  /* font-weight: regular; */
 }

.createAccount {
  /* background: linear-gradient(
    141deg,
    rgba(185, 183, 198, 1) 0%,
    rgba(23, 30, 145, 1) 0%,
    rgba(13, 10, 10, 1) 54%,
    rgba(15, 25, 212, 1) 94%
  ); */
  background: transparent !important ;
  /* background: conic-gradient(from -60deg at 168.68% 21.33%, black 71deg, #0D0D70 190deg, #171738 254deg); */
  margin-bottom: 10px;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
}
.logoContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
}
.createAccount-back {
  background: linear-gradient(
    141deg,
    rgba(185, 183, 198, 1) 0%,
    rgba(23, 30, 145, 1) 0%,
    rgba(13, 10, 10, 1) 54%,
    rgba(15, 25, 212, 1) 94%
  );
  /* background: conic-gradient(from -60deg at 168.68% 21.33%, black 71deg, #0D0D70 190deg, #171738 254deg); */
  
  padding-top: 30px;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;

}
.logo{

  width: 100%;
  height: 100px
}
.LoginerrorContainer {
  @apply w-11/12 rounded-sm  p-3 bg-red-200 ring-1 ring-red-400;
}
/* .LoginerrorContainer *{
  font-family: "UbuntuRegular";
} */

.LoginerrorContainer p {
  @apply text-center font-light text-xs;
}
.actions-login {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  background-color: white;
  border-radius: 15px;
 
  
}


.loginDecore h1 {
  @apply text-4xl  self-center mt-auto mb-auto font-sans font-bold text-center text-white ms-2 leading-none;
}
.loginDecore h1{
  font-family: "Raleway" !important;
}
.actions-login button {
  @apply w-4/5 my-3 font-sans text-white rounded-md text-lg h-10 bg-primary-color;
}

.loginContent-login {
  
  width: 60dvh;
  height: 60dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: white;
  border-radius: 15px;
  box-shadow: 1px 1px 1px 1px #6968ff;
}

.loginDecore {
  @apply pt-32 w-3/5 mt-auto mb-auto h-full items-start;
  /* border:1px solid white; */
}

.loginDecore img {
  width: 100%;
  height: 100%;
}

.formWrapper-login {

  width: 75%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

.formWrapper-login *{
  font-family: "UbuntuRegular";
}
.actions-login *{
  font-family: "UbuntuRegular";
}
.LoginerrorContainer * {
  font-family: "UbuntuRegular" !important;
}
.cTextInput label{
  font-family: "UbuntuRegular" !important;

}

@media screen and (min-width: 768px) and (max-width: 1134px) {
  .createAccount {
      padding-top: 10%;
  }
}

@media screen and (min-width: 275px) and (max-width: 400px) {
  .loginContent-login {
  
    width: 60dvh;
    height: 65dvh;
  }
}

@media screen and  (max-width: 480px) {
  .createAccount {
      padding-right: 2%;
      padding-left: 2%;
  }
}