

.hoverCard-accepted-order{
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
    /* add a shadow that is right below not in the side */ 
    box-shadow: 0px 0px 5px 0px rgba(130, 126, 126, 0.75);
    /* box-shadow: 0px 0px 10px 0px #2B3674; */
    margin-bottom: 10px;
    cursor: pointer;
    width: 400px;
    height: 200px;
    margin: 20px;
    background: white;
    color: #090d25;
}

.hoverCard:hover {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}
.countdown-timer {
    display: flex;
    justify-content: space-around;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .time-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .time-number {
    font-size: 2em;
    font-weight: bold;
    color: #333;
    background-color: white;
    border-radius: 5px;
    width: 50px;
    margin: 5px;
  }
  
  .time-label {
    font-size: 1em;
    color: #777;
    margin: 5px;
  }
  
.hoverCard h1{
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin: 0px;
}

.hoverCard p{ 
    font-size: 14px;
    font-weight: 400;
    color: #090d25 !important;
    margin: 0px;
}

.hoverCard h3{
    font-size: 48px;
    font-weight: 600;
    color: #090d25 !important;
    margin: 20px;
    text-align: center;
    vertical-align: middle;

}

.uploadButton{
    font-size: 46px;
    font-weight: 600;
    color: #000;
    margin: 20px;
    text-align: center;
    vertical-align: middle;
   
}
.progressBar{
    width: 82%;
    height: 60px;
    background-color: #2B3674;
    border-radius: 10px;
    margin: 10px 0px;
    align-self: center;
    position: relative;
    display: flex;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    align-items: center;
    color: #fff;

}

.progressBar h3{
    font-size: 20px;
    font-weight: 600;
    color: white;
    margin: 0px;
}



.progressBarWrapper{
    width: 83%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}
.countdown-timer {
    display: flex;
    justify-content: space-around;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .time-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .flip {
    display: inline-block;
    perspective: 1000px;
    box-shadow: 2px 3px 4px gray;
    border-radius: 5px;
    background-color: #090d25;
    margin: 10px;
  }
  
  .time-number {
    font-size: 2em;
    font-weight: bold;
    color: #090d25;
    display: block;
    transform-style: preserve-3d;
    position: relative;
    transition: transform 0.6s ease-in-out;
    transform-origin: bottom;
  }
  
  .time-label {
    font-size: 1em;
    color: #777;
  }
  
  .time-number.flip {
    animation: flip 0.6s ease-in-out;
  }
  .flip {
    animation: flip 0.6s ease-in-out;
  }
  @keyframes flip {
    0% {
      transform: rotateX(0deg);
    }
    50% {
      transform: rotateX(-90deg);
    }
    100% {
      transform: rotateX(-180deg);
    }
  }
  .countdown-timer {
    display: flex;
    justify-content: space-around;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .time-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

  }
  
  .flip-card {
    display: inline-block;
    perspective: 1000px;
    background-color: #090d25;
    border-radius: 5px;
}
  
  .time-number {
    font-size: 2em;
    font-weight: bold;
    color: white;
    display: block;
    transform-style: preserve-3d;
    position: relative;
    transition: transform 0.6s ease-in-out;
    transform-origin: bottom;
    background-color: #090d25;

  }
  
  .time-label {
    font-size: 1em;
    color: #777;
  }
  
  .flipping .time-number {
    animation: flip 0.6s ease-in-out;
  }
  
  @keyframes flip {
    0% {
      transform: rotateX(0deg);
    }
    50% {
      transform: rotateX(-20deg);
    }
    100% {
      transform: rotateX(-90deg);
    }
  }
  