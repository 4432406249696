@media screen and (max-width: 767px){
   
    .mobileFooter .iconButtons{
         font-size: 30px;
         background-color: transparent;
         border:none;
         color: #03010E96;
         
     }

     .mobileFooter .iconButtons-selected{
        font-size: 30px;
        background-color: transparent;
        border:none;
        color: #03010e;
        background-color: white;
        border-radius: 50px;
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
    }
    .homeMain{
        padding-top:40px;
    }
    
    .headerRight{
        display: flex;
        height:50px;
        width:100px;
        margin:15px;
        flex-direction: row;

        background-color: transparent;
    }

     img{
        width:30px;
        height:30px;
        margin:5px;
        padding:0px;
        border-radius: 15px;
    }
    
     .leftNav{
         display: none;
         height: 100%;
         width:35dvh;
         margin:0px;
         padding:0px;
         position:fixed;
         box-shadow: 1px 1px 1px 1px darkgray;
         background-color: white;
     }
 
     .dashboardContent{
        /* overflow: scroll; */
         width:100%;
         height: 100%;
         display: flex;
         align-items: center;
             top:70px;
     }
 
  
 
     .singleCardContent h3{
        margin:0px;
        padding:0px;
        font-size: 20px;    ;
    }

     .cardIcon{
         font-size: 20px;;
         border-radius: 30px;;
         width:60px;;
         height: 60px;;
         margin:5px;
         overflow: hidden;
         background-color: #ffffff;
         color:#0a0738;
         display: flex;
         justify-content: center;
         align-items: center;
         margin:18px;
         margin-right:8px;
     }
     /* .singleGraph{
         width:48dvh;
         height:40dvh;
         background-color: white;
        overflow-x: scroll;
         margin:5px;

     } */
 
     .graphContent{
         width:100%;
         display:flex;
         flex-direction: column;
         
         align-items: center;
         margin:0px;
     }
 
     .mobileFooter{
         display: flex;
         flex-direction: row;
         background-color: #ffffff;
         position: fixed;
         bottom: 0px;
         height:50px;
         margin-top:20px;
         width:100%;
         z-index: 300;
         box-shadow: 1px 1px 1px 1px darkgray;
         justify-content: space-around;
        padding:4px;
        border-top: 1px solid #090909;
        overflow-y: scroll;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

     }
     .headerTool img{
        width:50px;
        height:50px;
        margin:5px;
        padding:0px;
        border-radius: 25px;
        cursor: pointer;
    }
     .headerTool{
         width:100%;
         height:70px;
         background-color: white;
        padding:0px;
        position: fixed;
        top:0px;
         margin: 0px;;
         color:black;
         align-self: flex-end;
         justify-content: space-around;
         display:flex;
         flex-direction: row;
         z-index: 400;
         border-radius: 25px;
     }   

     .headerLeft p{
        font-size: 14px;
        color: #090834;
     }

     .headerLeft h2{
        font-size: 14px;
        color: #090834;
     }

     .headerTool{
        background-color: #fff;
        margin-bottom: 0px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
 }