@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Railway";
  src: local("Railway"),
   url("./../../fonts/raleway/Raleway-Regular.ttf") format("truetype");
  /* font-weight: 400 !important; */
  font-weight: normal !important;
 }




/* 
.bodyContent-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #121449;
  width: 100%;

  justify-content: center;
} */

.bodyContent-2 {
  @apply flex flex-col items-center bg-[#121449] w-full justify-center pb-32;
}

.howItWorks {
@apply flex flex-col h-full w-full;
}


.primeryButton {
  @apply w-[30dvh] h-[8dvh] flex flex-row items-center justify-evenly text-[white] mt-[5dvh] m-2.5 p-[3px] rounded-[70px] border-[none];
  background: #6968ff;
}
/* .primeryButton {
  width: 30dvh;
  height: 8dvh;
  background: #6968ff;
  border-radius: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px;
  padding: 3px;
  margin-top: 5dvh;
  border: none;
  color: white;
} */
.toggleButton > button:hover {
  background-color: #ffffff00;

  cursor: pointer;
  
}

.toggleOption-selected:hover {
  color: #000000;
  background-color: #ffffff !important;
  cursor: pointer;
  
}

.toggleOption:hover {
  background-color: #ffffff00 !important;
  cursor: pointer;
  
}

.bottomBody-2 {
  @apply w-full flex flex-col justify-center mt-10 items-center;
}
/* .bottomBody-2 {

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */


/* .content_list {
  @apply flex flex-row justify-evenly items-center mt-10 w-full h-full flex-wrap m-0 p-0;
} */
.content_list {

  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  width: 55%;
  margin: auto;
  height: 66dvh;
  margin-top: -33dvh;
  margin-bottom: 50px !important;
  /* padding: 20px; */
  /* flex-wrap: wrap; */
}

@font-face {
  font-family: "ubuntu-eyu"; 
  src: local("ubuntu-eyu"),
   url("../../fonts/ubuntu/Ubuntu-Regular.ttf") format("truetype");

 }
.bottomBody-2 .textContaint {
  @apply text-[white] w-4/5 text-[2.4dvh] flex relative justify-center text-start m-0;
font-family: 'ubuntu-eyu';

}

.bottomBody-2 .textContaint-tables{
  @apply text-[white] w-4/5 text-[2.4dvh] flex  relative justify-center text-start m-0;
  flex-direction: column;
font-family: 'ubuntu-eyu';

}

p{ 
  font-family: 'ubuntu-eyu' !important;
  
}

.bottomBody-2 .textContaint-tables table tr  {
  @apply text-[white] text-[2.4dvh] font-bold;
  border: 1px solid #ffffff;
}

/* .bottomBody-2 .textContaint {
  color: white;
  width: 80%;
  font-size: 2.3dvh;

  margin: 0;
  display: flex;
  position: relative;
  font-family: sans-serif;
  justify-content: center;
  text-align: start;
} */


.toggleButton {
  @apply w-[40dvh] h-[9dvh] relative flex flex-row items-center justify-evenly top-[-4dvh] m-0 p-[5px] rounded-[70px];
  background: #6968ff;
}
/* .toggleButton {
  width: 40dvh;
  height: 9dvh;
  background: #6968ff;
  border-radius: 70px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  top: -3dvh;
  padding: 5px;
  margin: 0px;
} */


.toggleOption {
  @apply w-[20dvh] h-[90%] no-underline bg-transparent text-[#ffffff] flex font-bold justify-center items-center p-[5px] rounded-[70px] font-sans ;

}
/* .toggleOption {
  width: 20dvh;
  height: 90%;
  text-decoration: none;
  font-family: sans-serif;
  border-radius: 70px;
  background-color: transparent;
  color: black;
  display: flex;
  font-weight: bold;
  padding: 5px;
  justify-content: center;
  align-items: center;
} */

.toggleOption-selected {
  @apply w-[20dvh] h-[90%] flex no-underline justify-center items-center font-bold bg-[white] text-[black]  rounded-[70px];
  font-family: sans-serif;
}
/* .toggleOption-selected {
  width: 20dvh;
  height: 90%;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  border-radius: 70px;
  background-color: white;
  color: black;
  font-weight: 500;
  font-family: sans-serif;
} */


/* @media screen and (max-width: 768px) {
  .toggleButton {
    @apply w-[40dvh] h-[9dvh] relative flex flex-row items-center justify-evenly m-0 p-[5px] rounded-[70px];
    background: #6968ff;
  }
  .headerHero-2 {
    @apply flex flex-row justify-center items-center;
  }
  .bodyContent-2 {
    @apply bg-[#141971] w-full mt-[50px];
  }
} */
@media screen and (max-width: 768px) {
  .toggleButton {
    @apply w-[40dvh] h-[9dvh] relative flex flex-row items-center justify-evenly m-0 p-[5px] rounded-[70px];
    background: #6968ff;
  }
  .FAQ{
    display: block;
  }

  /* .toggleButton {
    width: 40dvh;
    height: 9dvh;
    background: #6968ff;
    border-radius: 70px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    padding: 5px;
    margin: 0px;
  } */
  .headerHero-2 {
    @apply flex flex-row justify-center items-center;
  }
  /* .bodyContent-2 {
    background-color: #121449;
    width: 100%;
    margin-top: 50px;
  } */

  .bodyContent-2 {
    @apply bg-[#121449] w-full mt-[50px];
  }
  

}

@media screen and (min-width: 275px) and (max-width: 480px) {
  .content_list{
    width: 80%;
    margin: auto;
    height: 26dvh;
    margin-top: -13dvh;
    margin-bottom: 100px;
  }
  .bottomBody-2 {
    @apply mb-[2rem];
  }
}

@media screen and (min-width: 481px) and (max-width: 600px) {
   .content_list{
    width: 80%;
    margin: auto;
    height: 36dvh;
    margin-top: -18dvh;
    margin-bottom: 100px;
  }
  .bottomBody-2 {
    @apply mb-[5rem];
  }
}

@media screen and (min-width: 601px) and (max-width: 767px) {
  .content_list{
   width: 78%;
   margin: auto;
   height: 40dvh;
   margin-top: -20dvh;
   margin-bottom: 100px;
 }
 .bottomBody-2 {
   @apply mb-[6rem];
 }
}




@media screen and (min-width: 768px) and (max-width: 999px) {
  .content_list{
    width: 78%;
    margin: auto;
    height: 50dvh;
    margin-top: -25dvh;
    margin-bottom: 100px;
  }
  .bottomBody-2 {
    @apply mb-[9rem];
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .content_list{
    width: 72%;
    margin: auto;
    height: 58dvh;
    margin-top: -29dvh;
    margin-bottom: 100px;
  }
  .bottomBody-2 {
    @apply mb-[11rem];
  }
}


@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .content_list{
    width: 70%;
    margin: auto;
    height: 64dvh;
    margin-top: -32dvh;
    margin-bottom: 100px;
  }
  .bottomBody-2 {
    @apply mb-[13rem];
  }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
  .content_list{
    width: 60%;
    margin: auto;
    height: 64dvh;
    margin-top: -32dvh;
    margin-bottom: 100px;
  }
  .bottomBody-2 {
    @apply mb-[13rem];
  }
}


@media screen and (min-width: 1601px) and (max-width: 1800px) {
  .content_list{
    width: 60%;
    margin: auto;
    height: 70dvh;
    margin-top: -35dvh;
    margin-bottom: 100px;
  }
  .bottomBody-2 {
    @apply mb-[15rem];
  }
}


@media screen and (min-width: 1801px) and (max-width: 1920px) {
  .content_list{
    width: 55%;
    margin: auto;
    height: 70dvh;
    margin-top: -35dvh;
    margin-bottom: 100px;
  }
  .bottomBody-2 {
    @apply mb-[15rem];
  }
}
