
.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    height: 80%;
}

.container h1{
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
}

.container .faq-sections{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height:100px;
    overflow-x: scroll;
}

.container .faq-sections .faq-header-section{
    background-color: lightgray;
    padding: 10px;
    width: 300px;
    border-radius: 5px;
    margin: 10px;
}

.container .faq-sections .faq-header-section-selected{
    background-color: rgb(70, 70, 70);
    padding: 10px;
    width: 300px;
    border-radius: 5px;
    margin: 10px;
    color: white;
}

.container .faq-sections .faq-header-section-selected h2{
    color: white;
}

.container .faq-sections .faq-header-section:hover{
    background-color: gray;
  
}

.container .faq-content .faq-item{
    /* background-color: rgba(194, 189, 189, 0.308); */
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
}

.container .faq-content .faq-item{

}