
@font-face {
    font-family: "UbuntuRegular";
    src: local("UbuntuRegular"),
     url("../../fonts/ubuntu/Ubuntu-Regular.ttf") format("truetype");
    font-weight: 400;
   }

  footer *{
    font-family: "UbuntuRegular" !important;
    
     /* font-family: 'Courier New', Courier, monospace; */
   }
.footer {
    position: relative;
    bottom:0;
    background-color: black;
    display: flex;
    width:100%;
    height: 400px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    color:white;
    font-family: FreeSerif;
    border-top: 1px solid #6968FF;
}

.social{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width:50dvh;
    height:20px;
    margin:15px;
    padding:0px;
}

.socialIcons{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width:100px;
    margin:0px;
    padding:0px;
    font-size: 35px;
}

.footer .section p{
    font-size: 3dvh;
    margin:0;
    padding:0;
    text-align: start;
    font-family: FreeSerif;
}

.footer .section{
    display: flex;
    flex-direction: column;
    /* background-color:red; */
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    width:30dvh;
    margin:0px;
    padding:10px;

}


.footer .section ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
    height:100%;
    font-size: 3dvh
}
.footer .section ul li{
    margin:5px;
}

.footer .socialSection iframe{
    width:70dvh;
    height:40dvh;
}

.footer .socialSection{
    display: flex;
    flex-direction: column;
    /* background-color:red; */
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    
    margin:0px;
    padding:10px;

}



@media screen and (max-width: 768px){

    .socialSection{
        display: none;

    }

    .section{
        display: none;
    }

    .footer .section{
        display:none;
    }

    .footer .socialSection iframe{
        display:none;
    }
  
    .footer .socialSection .social{
        display: flex;
    }

}