@media screen and (min-width: 320px) and (max-width: 480px){
    .topContent-banner h1{
        font-size: 2rem;
       }
       .trendingCreatorLists{
        flex-wrap: wrap !important;
        text-align: left !important;
        }
        .trandingCreators{
            flex-direction: column;
        }
}
@media screen and (min-width: 481px) and (max-width: 768px){
    .topContent-banner h1{
        font-size: 2.3rem !important;
       }
     
}

@media screen and (min-width: 769px) and (max-width: 991px){
    .topContent-banner h1{
        font-size: 2.5rem !important;
       }
}

@media screen and (max-width: 768px){

    .homeBusinessLeftSide{
       
        width:90% !important;
    }

    .creatorListing{
    
        width:100% !important;
        margin: auto;
        height: fit-content;
     
    }


 
}