.createAccount {
  background: linear-gradient(
    141deg,
    rgba(185, 183, 198, 1) 0%,
    rgba(23, 30, 145, 1) 0%,
    rgba(13, 10, 10, 1) 54%,
    rgba(15, 25, 212, 1) 94%
  );
  /* background: conic-gradient(from -60deg at 168.68% 21.33%, black 71deg, #0D0D70 190deg, #171738 254deg); */

  width: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  overflow: scroll
}
.logoContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
}
.logo{

  width: 100%;
  height: 100px
}

.createAccount-signup {
  background: white;
  /* background: conic-gradient(from -60deg at 168.68% 21.33%, black 71deg, #0D0D70 190deg, #171738 254deg); */

 
  padding-top: 30px;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}

.errorContainer {
    @apply w-full rounded-sm  p-3 bg-red-200 ring-1 ring-red-400;
}

.errorContainer p {
    @apply text-left font-light text-xs;
}
.actions {
  @apply flex flex-row justify-between w-full gap-5 my-5 ;
}

.actions button {
  @apply flex-1 font-sans font-bold text-sm p-2 rounded-sm text-white;
}

.firstBut {
    @apply w-full;
}
.loginContent {
  @apply w-2/6 h-fit my-2 flex flex-col justify-start items-center overflow-scroll rounded-md shadow-md bg-white p-3;
}
.loginTitle {
  @apply m-4;
}
.loginTitle h1 {
    @apply font-sans text-xl font-bold;
  }
.loginDecore {
  width: 80dvh;
  height: 80dvh;
  margin-left: 20px;
  /* border:1px solid white; */
}

.loginDecore img {
  width: 100%;
  height: 100%;
}

.formWrapper {
  @apply w-[85%] flex-1 py-4;
}
