.chat-container {
    position: fixed;
    bottom: 140px;
    right: 40px;
    width: 350px;
    z-index: 1000; /* Ensure it floats on top */
    background-color: rgb(184, 177, 177);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
  }
  
  .chat-box {
    max-height: 450px;
    height: 450px;
    padding: 10px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    margin-bottom: 10px; /* Space above the input */
  }
  
  .chat-bubble {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    max-width: 80%;
    position: relative;
    font-size: 14px;
  }
  
  .chat-bubble.user {
    background-color: #d1e7ff;
    margin-left: auto;
    border-bottom-right-radius: 0;
  }
  
  .chat-bubble.bot {
    background-color: #e2e2e2;
    margin-right: auto;
    border-bottom-left-radius: 0;
  }
  
  .chat-bubble p {
    margin: 0;
  }
  
  .chat-input-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .chat-input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 10px 0 0 10px;
    outline: none;
    font-size: 14px;
  }
  
  .send-button, .meet-button {
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  
  .send-button {
    background-color: #007bff;
    color: #fff;
    border-radius: 0px;
    margin: 0px;
    height: 45px;  
  }
  
  .send-button:hover {
    background-color: #0056b3;
  }
  
  .meet-button {
    background-color: #0056b3;
    color: #fff;
    border-radius: 0 10px 10px 0;
    
  }
  
  .meet-button:hover {
    background-color: #2c8e43;
  }
  .toggle-button {
    position: fixed;
    bottom: 60px;
    right: 40px;
    background-color: #090D25;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1001; /* Ensure it's above the chat box */
    height: 70px;
    width: 70px;
    border-radius: 35px;
  }
  
  .toggle-button:hover {
    background-color: #0056b3;
  }
  