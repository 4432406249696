.cTextInput {
    @apply relative w-full flex justify-center items-start flex-col my-1;
  }
  
  .textInput {
    @apply block w-full font-sans ring-1 ring-gray-200 rounded-md py-1.5 pl-3 pr-20 my-1 text-gray-900 ring-1  ring-gray-300 placeholder:text-gray-400 focus:ring-1 ring-slate-300 focus:ring-gray-400 focus:ring-indigo-600  border-gray-500 sm:text-sm sm:leading-6;
  }
  
  .passwordField {
   @apply block w-full font-sans ring-1  ring-gray-200 rounded-md py-1.5 pl-3 pr-20 my-1 text-gray-900 ring-1  ring-gray-300 placeholder:text-gray-400 focus:ring-1 ring-slate-300 focus:ring-gray-400 focus:ring-indigo-600  border-gray-500 sm:text-sm sm:leading-6;
  }
  
  .cTextInput textarea {
    @apply border rounded-sm text-lg m-1 p-0 font-sans w-full h-28;
  }
  .cTextInput label {
    @apply text-sm font-semibold font-sans pl-1 mt-1 ;
  }

  .error {
    border: 1px solid red;
  }