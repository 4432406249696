


.googleAuth{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    justify-content: center;
    height:100%;
    width:100%;
    background-color: #f5f5f5; ;
    justify-content: center;
}


.middleContaint{
    width:80dvh;
    height: 46dvh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding:10px;
    box-shadow: 0px 0px 10px 0px rgba(130, 126, 126, 0.75);
    border-radius: 5px;;
}   

.googleAuth h1{
    margin:5px;
    text-align: center;
    padding:0px;
    font-size: 20px;
}

.googleAuth h2{
    margin:0px;
    padding:0px;
}

.primaryButton{
    background-color: #4285f4;
    padding:20px;
    border-radius: 5px;
    align-self: center;
    margin:40px;
    font-size: 26px;
    border: none;
    color: white;
}

.middleHeader{
    display: flex;
    flex-direction: row;
    background-color: transparent;
    width: 100%;
    padding:5px;
    color:black;
    border-radius: 5px;
    box-shadow: 1px solid blue;
}


.iconMiddleHeader{
    font-size: 40px;;
    color:red;
}