@font-face {
  font-family: "UbuntuRegular";
  src: local("UbuntuRegular"),
   url("./fonts/ubuntu/Ubuntu-Regular.ttf") format("truetype");
  font-weight: 400 !important;
  font-weight: normal !important;
 }
 @font-face {
  font-family: "Raleway";
  src: local("Raleway"),
   url("./fonts/raleway/Raleway-Regular.ttf") format("truetype");
  font-weight: 400 !important;
 }
 h1{
  font-family: "Raleway" !important;
 }
 p, button, h3, h4, h5{
  font-family: "UbuntuRegular" !important;


 }
 

body {
  margin: 0;
  font-family: "UbuntuRegular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
