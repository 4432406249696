@font-face {
  font-family: "UbuntuRegular";
  src: local("UbuntuRegular"),
   url("./fonts/ubuntu/Ubuntu-Regular.ttf") format("truetype");
  /* font-weight: 400 !important; */
  font-weight: normal !important;
 }
 @font-face {
  font-family: "Raleway";
  src: local("Raleway"),
   url("./fonts/raleway/Raleway-Regular.ttf") format("truetype");
  font-weight: 400 !important;
 }
 h1, h2{
  font-family: "Raleway" !important;
 }
 p, button, h4, h5{
  font-family: "UbuntuRegular" !important;


 }




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

*{
  transition: all 0.3s ease;

}