.login {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100dvh;
  width: 100%;
  background-color: hsl(263, 43%, 88%);
  background: linear-gradient(
    141deg,
    rgba(185, 183, 198, 1) 0%,
    rgba(23, 30, 145, 1) 0%,
    rgba(15, 25, 212, 1) 54%,
    rgba(15, 25, 212, 1) 94%
  );
}

.loginScreenBox1 {
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 255, 0.189);
  display: flex;
  /* background-image: url('https://cardsetter.com/images/img_2jdScSj6AcUFxQ4FG5q99u/creator-examples.jpg?fit=outside&w=1600');
    background-size: cover;
    */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 5px solid rgb(1, 1, 29);
}

.loginScreenBox {
  width: 50%;
  height: 100%;
  background-color: rgba(7, 65, 74, 0.287);
  display: flex;
  /* background-image: url('https://media.istockphoto.com/id/1365436662/photo/successful-partnership.jpg?s=612x612&w=0&k=20&c=B1xspe9Q5WMsLc7Hc9clR8MWUL4bsK1MfUdDNVNR2Xg=');
    background-size: cover;
   */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginScreenBox:hover {
  background-color: rgba(255, 0, 0, 0.287);
  background-blend-mode: darken;
  cursor: pointer;
}

.loginScreenBox1:hover {
  background-color: rgba(255, 0, 0, 0.287);
  background-blend-mode: darken;
  cursor: pointer;
}

.loginScreenBox1 button {
  @apply font-bold text-lg text-white rounded-lg w-2/4 cursor-pointer p-5 me-4 bg-primary-color hover:bg-white hover:text-primary-color;
}

.loginScreenBox button {
  @apply font-bold text-lg text-white rounded-lg w-2/4 cursor-pointer p-5 me-4 bg-primary-color hover:bg-white hover:text-primary-color;
}

button:hover {
  background-color: #00bfa6;
  cursor: pointer;
}
