.bodyComponent{
    width: 60%;
    justify-content: center;
    padding: 30px;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 320px) and  (max-width: 767px){
    .bodyComponent{
width: 95%;
    }
}
@media screen and (min-width: 1440px){
    .bodyComponent{
    width: 45%;
    }
}

/* @media screen and (min-width: 481px) and  (max-width: 767px){
    .bodyComponent{
width: 80%;
    }
} */