.aboutUs {
    background-color: #121449;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: all 1s ease-in-out;
    padding-bottom: 50px;
  
    overflow: hidden;
  }