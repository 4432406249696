
.orderScreen{
    width:100%;
    height: 600px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
}



.video_detail{
    @apply  flex flex-row w-1/2;
}

.video_detail *{
    
}


.order_video_section{
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 700px;
    margin:10px;
    padding:10px;
   
    /* border:3px solid black; */
}
.order_video_section iframe{
    width:100%;
   
    /* box-shadow: 2px 2px 2px 2px lightgray; */
}

.order_video_section h3{
    margin:0px;
    padding:3px;
}

.order_video_section p{
    text-align: start;
    padding:0px;
    color: #2B3674;
    align-self: flex-start;
    margin:3px;
}

.buttonsList{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 10px;
}

.buttonsList button{
    width: 20%;
    height: 40px;
    border-radius: 10px;
    border: none;
    background-color: #F2F2F2;
    color: #000000;
    font-size: 14px;
    /* font-weight: 600; */
    cursor: pointer;
}
.rejectButton{
    background-color: red !important;
    color: white !important;
}
.rejectButton:hover{
    background-color: rgb(255, 255, 255) !important;
    color: red !important;
    border: 1px solid red;
}
.mobileConnect{
    display: none;
}


@media screen and (max-width: 568px) {
    .mobileConnect{
        display: flex;
        position: absolute;
        bottom: 140px;
        right: 20px;
        background-color: #090D25;
        width: 50px;
        height: 50px;
        z-index: 4000;
        border-radius: 100px;
        justify-content: center;
        color: white;
        align-items: center;
        box-shadow: 2px 2px 2px 2px lightgray;
    }
}