@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: all 1s ease-in-out;

  overflow: hidden;
}
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.middleBody {
  @apply flex flex-row align-middle justify-evenly w-5/6 self-center mx-auto;
}

.card-content{
  font-family: 'ubuntu-eyu';
}

@font-face {
  font-family: "Raleway-eyu";
  src: local("Raleway-eyu"),
   url("../../fonts/raleway/Raleway-Regular.ttf") format("truetype");
  font-weight: 1000 !important;
 }


.middle-titles{
  font-family: 'Raleway-eyu';

}

.howItWorkss{
  display: flex;
  flex-direction: row !important;
  justify-content:space-evenly;
width: 80% !important;
margin:auto !important;
  align-items: center;
  
}


.middleBody div {
  @apply flex flex-col items-start justify-start overflow-hidden w-[30%] h-fit cursor-pointer shadow-xl border border-gray-200 hover:shadow-2xl;
}
.middleBody div img {
  @apply w-full mx-auto rounded-xl h-80 mt-1;
}

.middleBody div h2 {
  @apply font-sans font-bold text-2xl my-2 mx-6;
}
.middleBody div p {
  @apply font-sans font-semibold w-11/12 mx-3 text-left text-lg mb-4 mx-6;
}

.middleBody h2 {
  font-size: 5dvh;
  margin: 0;
  padding: 0;
  text-align: start;
}

.middleBody p {
  text-align: start;
  font-size: 2dvh;
  font-family: Ubuntu;
}

.bottomBody {
  @apply w-full self-center flex flex-col justify-center items-center flex-wrap pb-12;
}



.bottomBody .firstBottomContent {
  @apply flex flex-col justify-center align-middle items-center w-[60%] overflow-hidden m-4;
}

.bottomBody .firstBottomContent p {
  @apply text-left text-black font-normal text-xl font-sans;
}
.bottomBody .firstBottomContent h1 {
  @apply text-left font-bold text-4xl font-sans my-3;
}

/* @media screen and (min-width: 601px) and (max-width: 768px) {
  .middleBody div h2 {
    @apply font-sans font-bold text-xl my-2 mx-6;
  }
  .middleBody div p {
    @apply font-sans font-semibold w-11/12 mx-3 text-left text-sm mb-4 mx-6;
  }
  .bottomBody .firstBottomContent {
    @apply w-4/6;
  }
  .middleBody {
    @apply flex flex-row  justify-center align-middle  w-full self-center;
  }
  .middleBody div {
    @apply flex flex-col self-center items-start justify-start overflow-hidden w-5/6 h-fit cursor-pointer  rounded-xl shadow-xl border border-gray-200 hover:shadow-2xl;
  }
  .middleBody div img {
    @apply w-full h-1/3;
  }
  .bottomBody .firstBottomContent p {
    @apply text-left w-full text-black font-normal text-sm font-sans;
  }
  .bottomBody .firstBottomContent h1 {
    @apply text-center font-bold text-xl font-sans my-3;
  }
} */

/* @media screen and (max-width: 600px) {
  .bottomBody .firstBottomContent {
    @apply w-5/6;
  }
  .middleBody {
    @apply flex flex-col justify-center align-middle  w-full self-center;
  }
  .middleBody div {
    @apply flex flex-col self-center items-start justify-start overflow-hidden w-5/6 h-fit cursor-pointer  rounded-xl shadow-xl border border-gray-200 hover:shadow-2xl;
  }
  .middleBody div img {
    @apply w-full h-1/3;
  }
  .bottomBody .firstBottomContent p {
    @apply text-left w-full text-black font-normal text-lg font-sans;
  }
  .bottomBody .firstBottomContent h1 {
    @apply text-center font-bold text-2xl font-sans my-3;
  }
} */

@media screen and (max-width: 999px) {
.howItWorkss{
  flex-direction: column !important;
  
}
.inside{
  margin-top: 4%;
}
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .bottomBody .firstBottomContent {
    @apply w-[85%];
  }
  .bottomBody .firstBottomContent h1{
    @apply text-3xl
  }
  }

@media screen and (min-width: 481px) and (max-width: 767px) {
.bottomBody .firstBottomContent {
  @apply w-[80%];
}
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .bottomBody .firstBottomContent {
    @apply w-[75%];
  }
}
@media screen and (min-width: 1001px) and (max-width: 1300px) {
  .bottomBody .firstBottomContent {
    @apply w-[70%];
  }
}

@media screen and (min-width: 1301px) and (max-width: 1500px) {
  .bottomBody .firstBottomContent {
    @apply w-[65%];
  }
}

@media screen and (max-width: 768px) {
.forImage{
  display: flex;
  justify-content: center;
width: 97%;
}
}
