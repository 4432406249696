@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.roundButtonBorder {
  @apply font-semibold font-sans text-lg px-6 py-2 bg-primary-color text-white cursor-pointer  hover:bg-white hover:text-primary-color duration-300 rounded-lg;
}

.roundButtonBorder-black {
  border-radius: 10px;
  border: 2px solid black;
  padding: 3px;
  width: 100px;
  height: 30px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: black;
  text-decoration: none;
  font-size: 3dvh;
}

.flavorText {
  color: #6968ff;
  bold: true;
}
