

@media screen and (max-width: 480px) {

    .orderScreen{
        width: 100% !important;
        height: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        margin-left:0 !important;
        margin: 0 !important;
        

    }

     .campaignList-header{
        min-width: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 0 !important;
        overflow-x: scroll !important;
        height: 50px !important;
        overflow-y: hidden;
        margin-top: -10px !important;

    }   

    .option{
        min-width: 200px;
    }
    
    .option-selected{
        background-color: #090D25;
        color: white;
        min-width: 200px !important;
    }

    .dashboardContent {
        width: 100% !important;
       margin: 0 !important;
       overflow: hidden;
       display: flex;
       flex-direction: column;
       justify-content: center;
        align-items: center;
    }

    .centerHeader{
        text-align: cecenterHeadernter;
    }
   .stepper{
    margin-left: -39px !important;
   }
    .containerStatus{
        background: #83859436;
        margin: 0px;
        overflow-x: scroll;
        overflow-y: hidden;
        width:100%;
        height: 150px;
        border-radius: 0;
    }
    .circle {
        width: 50px !important;
        height: 50px !important; 
        border-radius: 35px;
        margin: 5px;
        background: #090d25;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 2px 3px 4px gray;
      
      }
      .singleOrder{
        display: flex !important;
        margin-bottom: 10px;
        width: 100% !important;
        flex-direction: row !important;
        overflow: hidden;
        border-radius: 0;
        margin: 0px !important;
        overflow-y: scroll !important;
        padding-bottom: 100px;
        align-items: center !important;
        justify-content: center !important;
        height: calc(100vh - 10px) !important;
    }
     .headerBoxer p {
        color: #090d25 !important;
        margin-top: 0;
        font-size: 10px !important;
      }

      .headerBoxer-highlighted p {
        color: #090d25;
        margin-top: 0;
        font-size: 10px;
      }

      .circle *{
        font-size: 25px !important;
      }
      
      
      .highlighted-circle {
        width: 85px;
        min-height: 85px;
        border-radius: 45px;
        margin: 5px;
        background: #090d25;
        border: 5px solid rgb(99, 94, 143);
        /* border: 7px soldisplay: flex; margin-bottom: 10px; width: 100%; flex-flow: column wrap; margin-left: 50px;id rgb(172, 26, 26); */
      
        box-shadow: 2px 3px 4px gray;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .headerBoxer{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 90px;
          height:150px;
          margin-bottom: -80px;
          background-color: transparent;
      }
      
      .headerBoxer-highlighted{
        margin-bottom: -16px;
      }

      .card-campaign-detail{
        background-color: #6968ff;
        background-color: rgb(18, 13, 92);
        background-color: #090d25;

        display: flex;
        justify-content: center;
        color: white;
        align-items: center !important;
        /* min-width: 95%; */
        max-width: 44%;

        margin: 0px !important;
        align-self: center !important;
        margin-bottom: 10px;
        margin-left: 5px !important;
        height: 150px;

        display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
  border-radius: 5px;;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
  margin-bottom: 10px !important;
      }

      .order_video_section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:89% !important;
        height: 400px !important;
        overflow: visible;
        border-radius: 5px;
        padding-bottom: 50px;
      }
      .whiteSpace{
        height: 30px;
      }

      .card-campaign-detail h1{
        font-size: 15px;
        margin: 0;
        color: white;
      }
 
}


