.canvasContainer {
    width: 100% !important;
    margin: auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease;
    border: 1px solid #f0f0f0;
    
}

.canvasContainer:hover {
    transform: translateY(-4px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.canvasContainer canvas {
    width: 100% !important;
    height: auto !important;
    transition: opacity 0.2s ease;
}

.canvasContainer:hover canvas {
    opacity: 0.95;
}