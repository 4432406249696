.createCampagin {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.formWrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  padding: 20px;
  overflow-y: scroll;
  /* padding-top:600px; */
  padding-bottom: 10px;
  margin-bottom: 30px;
  
}

.parent{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: all 1s ease-in-out;
  overflow: hidden;
  background-color: #000000b7;
  animation: fadeIn 2s ease-in-out forwards;

}

.popup{
  background-color: white;
  width: 38%;
  height: 350px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  transition: all 1s ease-in-out;
  border-radius: 10px;
}


@media screen and (max-width: 768px) {
  .popup{
    width: 90%;
    height: 350px;
  }
}

.popup-inner-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 10px;
  /* background: linear-gradient(141deg,#b9b7c6,#171e91 0,#0d0a0a 54%,#0f19d4 94%); */
  background-color: #000000;
  height: 100%;
  width: 40%;
  overflow: hidden;
}


.popup-inner-warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 10px;
  /* background: linear-gradient(141deg,#b9b7c6,#171e91 0,#0d0a0a 54%,#0f19d4 94%); */
  background-color: #b16d14;
  height: 100%;
  width: 40%;
  overflow: hidden;
}

.popup-inner-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 10px;
  /* background: linear-gradient(141deg,#b9b7c6,#171e91 0,#0d0a0a 54%,#0f19d4 94%); */
  background-color: #eb2e2e;
  height: 100%;
  width: 40%;
  overflow: hidden;
}


.popup-inner-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 10px;
  /* background: linear-gradient(141deg,#b9b7c6,#171e91 0,#0d0a0a 54%,#0f19d4 94%); */
  background-color: #000000;
  height: 100%;
  width: 40%;
  overflow: hidden;
}

.popup-inner_right .button-container{
  display: flex;
  flex-direction: row;
  width: 92%;
  align-self:center;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
 
}

.popup-inner_right .button-container .posetiveButton{
  background-color: #151847f8;
  color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
  width: 120px;

}

.popup-inner_right .button-container .negativeButton{
  background-color: #7c2a2a;
  color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
  width: 120px;
}


.popup-inner_right{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  border-radius: 10px;
 height: 100%;
  width: 60%;
  overflow: hidden;

}

.popup-inner_right  .errorContent{
 text-align: start;
}

.popupInner{
  margin: 20px;
  margin-bottom: 0px;
  color: rgb(158, 158, 158);
  font-size: 30px;
  text-align: start;
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
}

 .iconPopup{
  margin: 20px;
  margin-bottom: 0px;
  color: rgb(158, 158, 158);
  font-size: 130px;
  align-self: center;
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
}

.popup p{
  margin: 20px;
  margin-bottom: 0px;
}

.website-linker-container{
  display: flex;
  flex-direction: row;
  /* border: 1px lightgray solid; */
  border-radius: 5px;
  padding: 5px;
  margin-top: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left:0px;
  /* background-color: rgb(61, 61, 61); */
}

.website-linker-container_outer{
  display: flex;
  flex-direction: row;
  /* border: 1px lightgray solid; */
  border-radius: 5px;
  padding: 5px;
  margin-top: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left:0px;
  /* background-color: rgb(61, 61, 61); */
}

.website-linker-container_outer h3{
  width: 160px;
}

@media screen and (max-width: 768px) {
  .website-linker-container_outer h3{
    width: 90px;
    font-size: 14px;
    margin: 0px;
    padding: 3px;
  }
  
  .website-linker-container_outer{
    display: flex;
  flex-direction: row;
  /* border: 1px lightgray solid; */
  border-radius: 5px;
  padding: 5px;
  margin-top: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left:0px;

    /* background-color: rgb(61, 61, 61); */
  }
  .website-linker-container{
    /* margin-left: 100px; */
  }
  .website-linker-container input{
    border: 1px lightgray solid;
    border-radius: 15px;
    padding: 0px; 
    height: 50px;
    margin: 5px;
    max-width: 70px;
  
  }
  
  
}


.website-linker-container select:first-child{
  margin-left: 0px;
}

.website-linker-container select{
  padding: 15px;
  border-radius: 10px;
  margin: 5px;
  width: 200px;
}

.website-linker-container button{
  padding: 10px;
  background-color: rgb(81, 81, 161);
  height: 50px;
  width: 50px;
  border-radius: 15px;
  color: white;
  margin: 5px;
  font-size: 30px;
}

.website-linker-container .negative{
  background-color: orange;
}

.website-linker-container input{
  border: 1px lightgray solid;
  border-radius: 15px;
  padding: 0px; 
  height: 50px;
  margin: 5px;

}

.qr-container{
  display: flex;
  flex-direction: row;
  /* border: 1px lightgray solid; */
  border-radius: 5px;
  padding: 5px;
  margin-top: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left:10px;
  /* background-color: rgb(61, 61, 61); */
  /* border: 2px solid lightgray; */
}

.rightSide-payemt{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border-radius: 10px;
  /* background: linear-gradient(141deg,#b9b7c6,#171e91 0,#0d0a0a 54%,#0f19d4 94%); */
  margin: 10px;
  width: 400px;
  height: 200px;
  padding: 5px;

}

.payment-container{
  display: flex;
  flex-direction: row;
  border: 2px solid lightgray;
  /* border-radius: 10px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.payment-container-buisness{
  display: flex;
  flex-direction: row;
  border: 2px solid lightgray;
  /* border-radius: 10px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  
}

.rightSide-payemt h3{
  color: black;
  font-size: 30px;
  font-weight: bolder;

}

.verfication-code{
  display: flex;
  flex-direction: row;
  /* border: 1px lightgray solid; */
  border-radius: 2px;
  padding: 5px;
  margin-top: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left:10px;
  /* background-color: rgb(61, 61, 61); */
  border-bottom: 2px solid lightgray;

}

.website-linker-container input:active{
  border: 1px lightgray solid;
}

.trending-creators {
  @apply font-sans font-bold text-2xl text-left text-primary-color my-3;
}

.buttonArea {
  height: 100px;

  margin-bottom: 100px;
  width: 100%;
}

.tabs {
  width: 50%;
  margin-bottom: 15px;

}

@media screen and (max-width: 768px) {

  .tabs {
    width: 100%;
  }

}

.campaignContainer {
  width: 100vh;
  /* margin-left: 300px; */
  /* background-color: red; */
  /* padding-top: 5%; */
}

button:hover {
  transform: scale(0.95);
  transition: transform 0.7s;
}


.youTubeTelegram {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
gap: 30px;
overflow-y: scroll;


}

.youTubeButton {
  background: linear-gradient(270deg, #E65656 0%, #B84545 31.5%, #A53E3E 48.5%, #983939 68.5%, #7F3030 100%);
  ;
  font-size: 30px;
  width: 49%;
  padding: 45px;
  color: #fff;
  border-radius: 30px;
}

.youTubeButtonClicked {
  border: 5px solid #7F3030;
}

.telegramButton {
  background: linear-gradient(270deg, #7985C6 0%, #60699C 40.5%, #3B4060 100%);
  
  font-size: 30px;
  width: 49%;
  padding: 45px;
  color: #fff;
  border-radius: 30px;

}

.telegramButtonClicked {
  border: 5px solid #3B4060;
}

.tikTokButton {
  /* background: linear-gradient(90deg, #00F2EA 0%, #00C4BE 44.65%, #008C87 100%); */
  background: linear-gradient(to right, #000000, #333333);
  font-size: 30px;
  color: white;
  width: 100%;
  padding: 45px;
  border-radius: 30px;
  opacity: 0.4;

}

.tikTokButtonClicked {
  border: 5px solid #008C87;
}



.standardButton {
  background: linear-gradient(180deg, #7064BA 0%, #332D54 100%);
  font-size: 30px; 
  width: 30%;
  padding: 45px;
  color: #fff;
  border-radius: 30px;
  height: 500px;

}

.standardButtonClicked {
  border: 5px solid #332D54;
}

.descriptionSection{
  font-size: 16px;
  color: lightgray;
 
}

.plusButton {
  background: linear-gradient(180deg, #4E20D0 0%, #28106A 100%);
  font-size: 30px;
  width: 30%;
  padding: 45px;
  color: #fff;
  border-radius: 30px;
}

.plusButtonClicked {
  border: 5px solid #28106A;
}

.premiumButton {
  /* background: linear-gradient(90deg, #1D202B 0%, #636D91 100%);
   */
   background-color: #D3D3D3;
  font-size: 30px;
  width: 30%;
  padding: 45px;
  color: #fff;
  border-radius: 30px;
  opacity: 1;
  
} 

.premiumButton:hover{
  opacity: 0.5;
  background-color: #D3D3D3;
}

.premiumButton .descriptionSection{
  font-size: 16px;
  color: white;
}

.premiumButtonClicked {
  border: 5px solid #636D91;
}



.entertainmentButton {
  background: linear-gradient(180deg, #BA648D 0%, #332D54 100%);
  font-size: 30px;
  width: 49%;
  padding: 45px;
  color: #fff;
  border-radius: 30px;
}

.entertainmentButtonClicked {
  border: 5px solid #BA648D;
}

.educationButton {
  background: linear-gradient(180deg, #460DE9 0%, #28106A 100%);
  font-size: 30px;
  width: 49%;
  padding: 45px;
  color: #fff;
  border-radius: 30px;
}

.educationButtonClicked {
  border: 5px solid #28106A;
}

.question {
  font-size: 25px;
  text-align: left !important;
  color: #2D2CAB;
  font-weight: 500;
  margin-bottom: 20px;
}

.navigateButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.bottomButtons {
  background: linear-gradient(270deg, #494F8A 0%, #131524 100%);
  font-size: 20px;
  width: 15%;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}

.youTubeTelegram {

  overflow-y: scroll;
  }

.button-click-effect {
  transition: background-color 0.3s, box-shadow 0.3s;
}

.button-click-effect:focus,
.button-click-effect:hover {
  background-color: #f0f0f0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.letsGo {
  background: linear-gradient(90deg, #1D202B 0%, #636D91 100%);
  font-size: 20px;
  width: 30%;
  padding: 15px;
  color: #fff;
  border-radius: 5px;
  margin-top: 25px;
}

.congratulationText {
  color: #394076 !important;
  font-size: 40px !important;
  font-weight: 800;
}


/* animation */

/* Define transition styles */
.fade-enter {
  opacity: 0 !important;
}

.fade-enter-active {
  opacity: 1 !;
  transition: opacity 5000ms ease-in-out !important;
}

/* Define the animation */


/* Apply the animation to the transition container */
/* .slideInFromBottom {


  transition: opacity 0.5s cubic-bezier(0.42, 0, 0.58, 1), transform 0.5s cubic-bezier(0.42, 0, 0.58, 1);
}

@keyframes slideInFromBottom {
  0% {
    opacity: 0 !important;
 
  }
  100% {
    opacity: 1 !important;

  }
} */
@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Apply the animation to the element */
.slideInFromRight {
  animation: slideInFromRight 1.5s ease-in-out forwards;
  /* Adjust animation duration and timing function as needed */
}

/* fadeIn */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Apply the animation to the element */
.fadeIn {
  animation: fadeIn 2s ease-in-out forwards;
  /* Adjust animation duration and timing function as needed */
}

.radio-container{
  margin-bottom: 20px;
  /* Add some space between checkboxes */
  display: flex;
  align-items: flex-start;
  /* background-color: rgb(233, 231, 231); */
  /* padding: 15px; */
  border-radius: 10px;
  flex-direction: row;
  margin-top: 20px;
}

.radio-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  align-content: space-evenly;
  /* gap: 10px; */
  /* background-color: red; */
  flex-wrap: wrap;
  margin-bottom: 10px; /* Adjust spacing between radio button groups */
}

.radio-container input{
  padding: 5px;
  border-radius: 2px;
  height: 55px;
  align-self: flex-start;
  width: 100%;
  border-bottom: 1px solid lightgray;
  margin: 0px;  
  margin-bottom: 20px;
  
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px rgba(185, 41, 41, 0.623);
  }
  50% {
    box-shadow: 0 0 20px rgba(185, 41, 41, 1);
  }
  100% {
    box-shadow: 0 0 10px rgba(185, 41, 41, 0.623);
  }
}

.expectedView{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  gap: 20px;
  margin-top: 20px;
  font-size: 46px;
  align-self: flex-start;
  border-bottom: 1px solid lightgray;
}

.expectedView:focus{
  outline: none;
}

.borderenabled{
  /* border: 1px solid red; */
  box-shadow: 0 0 10px rgba(185, 41, 41, 0.623);
  animation: glow 2s infinite;
}
.radio-container-single{
  margin-bottom: 10px;
  /* Add some space between checkboxes */
  display: flex;
  align-items: flex-start;
  background-color: rgba(233, 231, 231, 0.541);
  padding: 15px;
  border-radius: 10px;
  flex-direction: row;
  width: 200px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  gap: 20px;
}

.radio-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust spacing between radio button groups */
}

.radio-container input[type="radio"] {
  margin-right: 5px; /* Adjust spacing between radio button and label */
}

.radio-container label {
  font-size: 16px; /* Adjust label font size */
  color: #333; /* Adjust label color */
}
/* 
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #D9D9D9;
  border-radius: 4px;
  outline: none;
  margin-right: 10px;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: #D9D9D9;
}

input[type="checkbox"]+label {
  cursor: pointer;
} */

/* Customizing the appearance of the checkmark */
input[type="checkbox"]+label::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 5px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin-right: 3px;
}

input[type="checkbox"]:checked+label::before {
  content: "\2713";
  /* Unicode character for checkmark */
  color: #fff;
  font-size: 14px;
  position: relative;
  top: -1px;
}

.checkbox-container label {
  color: #666666;
}


/* cong */

/* Congratulations.css */

/* Congratulations.css */

@keyframes confetti-fall {
  0% {
    transform: translateY(-100px) rotate(0deg);
  }

  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}

.congratulations-container {
  /* position: relative; */
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  /* overflow: hidden !important; */
}

.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fbc531;
  border-radius: 50%;
  opacity: 0.8;
  animation: confetti-fall 3s linear infinite;
}

/* Position the confetti randomly across the screen */
.confetti:nth-child(2n) {
  left: calc(25% + 10px);
}

.confetti:nth-child(3n) {
  left: calc(50% + 10px);
}

.confetti:nth-child(4n) {
  left: calc(75% + 10px);
}

.confetti:nth-child(5n) {
  left: calc(100% + 10px);
}

.confetti:nth-child(6n) {
  left: calc(-25% + 10px);
}

.confetti:nth-child(7n) {
  left: calc(-50% + 10px);
}

.confetti:nth-child(8n) {
  left: calc(-75% + 10px);
}

.congratulations-message {
  text-align: center;
  color: white;
  font-family: Arial, sans-serif;
  z-index: 1;
  /* Ensure the message is on top of the confetti animation */
}
.scroll-container {
  position: relative;
  height: 100%;
  position: relative;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.creator-list {
  display: flex;
  flex-direction: column;
 
  background-color: #e6e6e6;
  border-radius: 12px;
  padding: 15px;
  overflow-y: scroll;
  box-sizing: border-box;
}
.scroll-container::before,
.scroll-container::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 20px;
  pointer-events: none;
  z-index: 1;
}
.scroll-container::before {
  top: 0;
  background: linear-gradient(to bottom, rgba(230, 230, 230, 1), rgba(230, 230, 230, 0));
}
.scroll-container::after {
  bottom: 0;
  background: linear-gradient(to top, rgba(230, 230, 230, 1), rgba(230, 230, 230, 0));
}
.creator-list{
  display: flex;
  flex-direction: row;
  gap: 0px;
  background-color: #e6e6e61f;
  border-radius: 12px;
  padding: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-wrap: wrap;
  height: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.creator-card{
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  border: '1px solid #000';
  border-radius: 12px;
  background: #2B3674;
  color: white;
  padding: 20px;
  margin: 20px;
  margin-top: 2px;
  width: 200px;
  height: 70px;
  justify-content: space-between;
 
  margin-left: 0px;
}
.loader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.remove-creator{
  background: #bdbdbd;
  color: white;
  border: none;
  font-size: 12px;
  cursor: pointer;
  padding: 5px;
  margin: 0px;
  border-radius: 10px;
}

@media screen and (min-width: 280px) and (max-width: 768px){
  /* needs to change */
  .dashboardContent{
    display: block;
  }
  .campaignContainer {
    width: 80%;
    margin: auto;
    
  }

.bottomButtons{
  width: 35%;
}
.youTubeTelegram {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  margin-bottom: 0px;
  gap:0px;
  overflow-y: scroll;
}
.youTubeButton, .telegramButton, .tikTokButton, .standardButton, .plusButton, .premiumButton, .entertainmentButton, .educationButton {
 
  font-size: 20px;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;

}

.youTubeTelegram {

  overflow-y: scroll;
  }

.standardButton{
  height: 300px;
}

.plusButton {
  
  height: 300px;
}

.premiumButton{
  height: 300px;
  
}


.creator-list{
  display: flex;
  flex-direction: column;
  border: '1px solid #000';
  border-radius: 25px;
}

.creator-card{
  display: flex;
  flex-direction: column;

  border: '1px solid #000';
  border-radius: 25px;
  background: lightgray;
  padding: 20px;
}

p .description{
  font-size: 20px;
  text-align: start;;
  align-self: flex-start;
 
}

.question{
  font-size: 20px;
}
   
}
@media screen and (min-width: 769px) and (max-width: 999px){
 /* needs to change */
 .dashboardContent{
  display: block;
}

p .description{
  font-size: 20px;
  text-align: start;;
  align-self: flex-start;

}

.campaignContainer {
  width: 80%;
  margin: auto;
  
}

.bottomButtons{
width: 35%;
}
.youTubeTelegram {
display: flex;
flex-direction: column;
justify-content: space-between;
margin-bottom: 0px;
gap:0px;
overflow-y: scroll;

}

.youTubeButton, .telegramButton, .tikTokButton, .standardButton, .plusButton, .premiumButton, .entertainmentButton, .educationButton {

font-size: 20px;
width: 100%;
padding: 30px;
border-radius: 20px;
margin-bottom: 20px;

}


.question{
font-size: 30px;
}
.congratulationText{
  font-size: 40px !important;
}
   
}


@media screen and (min-width: 1000px) and (max-width: 1400px){
  /* needs to change */
  .dashboardContent{
   display: block;
 }
 .creator-card{
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: '1px solid #000';
  border-radius: 25px;
  background: lightgray;
  padding: 20px;
}



 .campaignContainer {
   width: 80%;
   margin: auto;
   
 }
 
 .bottomButtons{
 width: 35%;
 }
 .youTubeTelegram {
 display: flex;
 justify-content: space-between !important;
 margin-bottom: 0px;
 gap: 30px !important;
 overflow-y: scroll;
 }
 
 .youTubeButton, .telegramButton, .tikTokButton, .standardButton, .plusButton, .premiumButton, .entertainmentButton, .educationButton {
 
 font-size: 25px;
 width: 100%;
 padding: 40px;
 border-radius: 20px;
 margin-bottom: 20px;
 
 }
 .question{
 font-size: 30px;
 }
 .congratulationText{
   font-size: 40px !important;
 }
    
 }
/* */