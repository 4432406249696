.parentContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 60dvh;
    height: 60dvh; */

    width: 40%;
    background-color: white;
    border-radius: 20px;
    margin-top: 3%;
  
}

.parentContent button {
    width: 80%;
}


.parentContent iframe{
    /* width: 58dvh; */
    width: 97%;
    margin:5px;
    border-radius: 20px;
    
}

@media screen and (max-width: 768px){

    .parentContent{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* width: 40dvh;
        height: 60dvh; */
        width: 40%;
        background-color: white;
        border-radius: 15px;
    }
    
    .parentContent iframe{
        /* width: 38dvh; */
        margin:5px;
        width: 98%;
        border-radius: 15px;;
        
    }

}