.singleCreator {
  @apply flex flex-col items-start justify-start overflow-hidden w-[24%] h-fit cursor-pointer  rounded-xl shadow-xl border border-gray-200 hover:shadow-2xl;
}
.innerContainer {
  width: 100%;
}
.innerContainer img {
  width: 100%;
  height: 100%;

}
.image{
  width: 100%;
  height: 250px !important;
}
.forImage {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container */

}
.personalInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.innerContainer h3 {
  font-size: 13px;
}

.personalInfo h2 {
 @apply font-sans font-bold text-black text-xl
}

.singleCreatorInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px;
  padding-right: 10px;
  overflow: hidden;
}

.personalInfo p {
    @apply text-sm font-semibold font-sans text-black text-left my-1;

}

.personalInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 10px;
}
* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
.singleCreatorInfo button {
    @apply font-semibold text-sm text-white rounded-sm w-fit cursor-pointer py-2 px-4 me-4 bg-primary-color text-white hover:bg-primary-color hover:text-white;
}
.singleCreatorInfo h3 {
    @apply   ml-3 text-sm font-semibold font-sans text-black text-left my-1;

}

@media screen and (min-width: 481px) and (max-width: 999px){

  .singleCreator{ 
      width: 48% !important;
      height: fit-content;
  }

.innerContainer  img{
   width:100%;
   height: 100%;
   margin: auto;

}

}
