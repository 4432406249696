@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


.singleCardContent{
    width: 14em;
}
.singleCardContent p{    
   @apply text-sm font-semibold text-primary-color;
}

.singleCardContent h2{
    margin:0px;
    padding:0px;
}

 h3{
    font-size: 12px;
    font-weight: 600;
    color: rgb(109, 106, 106);
}