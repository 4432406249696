@media screen and (min-width: 320px) and (max-width: 480px){

    .singleCreator{
       
        width: 100%;
        height: fit-content;
        
       
    }

    .innerContainer{
        overflow: hidden;
        margin: 0;
        margin: auto;
    }

   .innerContainer  img{
       width:100%;
       height: 100%;
       margin: auto;
       
    }

}