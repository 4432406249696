.menu {
    width: 0;
    height: 100%;
    background-color: #110b25;
    position: fixed;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 1;
  }
  
  .menu.open {
    width: 250px;
  }
  
  .menu .toggle-button {
    padding: 16px;
    text-align: left;
    border: none;
    background: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
  
  .menu .menu-items {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .menu .menu-items li {
    padding: 10px;
    color: white;
    font-size: 18px;
    cursor: pointer;

  }
  
  .toggle-button:hover {
    background-color: #444;
  }
  