.public_profile_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-y: scroll;
}

.profile_pic_public{
    width: 300px;
    height: 300px;
    overflow: hidden;
    
}