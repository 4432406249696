.orderScreen{
    width:100%;
    height: 600px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
}



.video_detail{
    @apply  flex flex-row w-1/2;
}

.video_detail *{
    
}


.hoverCardPayment{
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
    /* add a shadow that is right below not in the side */ 
    box-shadow: 0px 0px 5px 0px rgba(130, 126, 126, 0.75);
    /* box-shadow: 0px 0px 10px 0px #2B3674; */
    margin-bottom: 10px;
    cursor: pointer;
    overflow: hidden;
    width: 400px;
    height: 200px;
    margin: 20px;
    background: white !important;
    color: #090d25 !important;
}

.hoverCardPayment:hover {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}
.order_video_section{
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 700px;
    margin:10px;
    padding:10px;
   
    /* border:3px solid black; */
}
.order_video_section iframe{
    width:100%;
   
    /* box-shadow: 2px 2px 2px 2px lightgray; */
}

.order_video_section h3{
    margin:0px;
    padding:3px;
}

.order_video_section p{
    text-align: start;
    padding:0px;
    color: #2B3674;
    align-self: flex-start;
    margin:3px;
}

.buttonsList{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 10px;
}

.buttonsList button{
    width: 20%;
    height: 40px;
    border-radius: 10px;
    border: none;
    background-color: #F2F2F2;
    color: #000000;
    font-size: 14px;
    /* font-weight: 600; */
    cursor: pointer;
}