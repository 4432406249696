@font-face {
    font-family: "UbuntuRegular";
    src: local("UbuntuRegular"),
     url("../../fonts/ubuntu/Ubuntu-Regular.ttf") format("truetype");
    /* font-weight: 400 !important; */
    font-weight: normal !important;
   }


   *{
    font-family: "UbuntuRegular";
   }
   .videoCard{
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 2%;
   }

   @media screen and (max-width: 768px) {

    .videoCard{
        width: 100%;
    }

   }

