
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";




.LoadingParent{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
    opacity: 0;

    visibility: hidden;
}

.bodyComponent{
  


    @apply flex w-5/12 bg-white flex-col justify-around rounded-sm p-3 gap-4;
}

.bodyComponent h2{
    @apply font-sans text-xl font-bold text-left ml-3;
}

.bodyComponent p{
 
    @apply font-sans text-sm text-left text-black ml-3;
}

.acceptButton{
  
     @apply font-bold text-sm w-1/4 text-white rounded-sm cursor-pointer py-2 px-2 me-4 bg-green-400 ;


    
}

.closeButton{
   
    @apply font-bold text-sm w-1/4 text-white rounded-sm  cursor-pointer py-2 px-2 me-4 bg-red-400 ;


}

.innerBody{

    @apply w-full h-fit flex flex-row justify-end align-middle;
}


.LoadingParent-active{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;
    opacity: 1;
    visibility: visible;
}

.loadingComponent{
    width: 70dvh;
    height: 30dvh;
   background-color: white;
   justify-content: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
