.videoUploaderForm {
    background-color: #2B3674;
    height: 500px;
    width: 80%;
    align-self: center;
    box-shadow: 0px 0px 10px 0px rgba(130, 126, 126, 0.75);
    padding: 30px;
    border-radius: 10px;
    ;
    margin-top: 100px;
    overflow: hidden;
    overflow-y: scroll;
}

.videoUploaderFormNew {


    width: 85%;
    min-height: 4cm;
    align-self: center;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px 0px rgba(130, 126, 126, 0.75);
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column !important;
    margin-top: 50px;
    overflow: hidden;
    overflow-y: scroll;
}

.videoUploaderFormNew input {
    width: 100%;
    /* height: 40px; */
    border-radius: 5px;

    border: none;
    margin: 5px;
    padding: 20px;
}
.videoUploaderFormNewTelegram {


    width: 85%;
    min-height: 40px;
    align-self: center;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px 0px rgba(130, 126, 126, 0.75);
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column !important;
    margin-top: 40px;
    overflow: none;
    overflow-y: none;
}

.videoUploaderFormNewTelegram input{


      width: 100%;
    height: 55px;
    border-radius: 5px;
    align-self: center;
    border: none;
    margin: 5px;
    padding: 20px;
}
.videoUploaderForm input {
    width: 100%;
    /* height: 40px; */
    border-radius: 5px;
    border: none;
    margin: 5px;
    padding: 20px;
}

.videoUploaderScreen h1 {
    color: white;
    margin: 5px;
    background-color: #1b1f36fa;
    padding: 15px;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 200px;
    /* background-color: red; */
}

.actionButtons button {
    width: 30%;
}


.innerDetail {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

    color: white;
    background-color: #070b20;
    opacity: 90%;
    padding: 20px;
    margin: 2px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(130, 126, 126, 0.75);
}

.innerDetail p {
    margin: 5px;
}

.videoUploaderScreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;

}

.buttons {
    margin-top: 2%;
    display: flex;
    justify-content: right;
}

.videoInfo {
    display: "flex";
    flex-direction: "column";
    width: 55%;
}

.thumbnail {
    width: 300px !important;
    height:300px;
}

.thumbnail img {
    width: 100%;
    height: 90%;
    object-fit: cover;
}

.videoContainer {
    display: "flex";
    flex-direction: "column";
    justify-content: "space-between";
    margin-top: 3%;
    width: 100%;
}

@media  screen and (max-width: 600px) {
    .videoUploaderForm {
        width: 100%;
    }

    .videoUploaderFormNew {
        width: 100%;
    }

    .videoUploaderForm input {
        width: 100%;
    }

    .videoUploaderFormNew input {
        width: 100%;
    }

    .videoInfo {
        width: 100%;
    }

    .thumbnail {
        width: 100% !important;
        height: none !important;
        position: relative;
       
    }

    .thumbnail img {
        width: 100%;
        height: 80%;
    }
    .videoContainer {
        width: 100%;
    }

    .videoUploaderScreen {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    
    }
    img{
        width: 100%;
    }

    .videoUploaderFormNew {


        width: 85%;
        height: 500px !important;
        align-self: center;
        margin-bottom: 20px;
        box-shadow: 0px 0px 10px 0px rgba(130, 126, 126, 0.75);
        padding: 30px;
        border-radius: 10px;
        display: flex;
        flex-direction: column !important;
        margin-top: 100px;
        overflow: hidden;
        overflow-y: visible;
    }
}