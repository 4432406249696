@media screen and (max-width: 768px) {
    .bodyComponent h2{
        padding-left: 15px;;
    }
    
    .bodyComponent p{
        text-align: start;
        margin:0px;
        font-family: 'sans-serif';
        padding:15px;
        padding-top:0px;
        padding-bottom: 0px;
    } 
    .bodyComponent{
        width: 50dvh;
        height: 30dvh;
       background-color: white;
       justify-content: center;
        border-radius: 5px;
        display: flex;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding:5px;
    }
    
}

.loadingComponent{
    width: 50dvh;
    height: 20dvh;
   background-color: white;
   justify-content: center;
    border-radius: 5px;
    display: flex;
    align-items: center;    
}

.loadingComponent img{
    width: 79px;
    height: 79px;
}

