@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.orderScreen {
  @apply w-10/12 flex flex-col mx-12;
}
.trending-creators {
  @apply font-sans font-bold text-xl text-left text-primary-color my-3;
}

.trending-creators {
    @apply font-sans font-bold text-xl text-left text-primary-color my-3 ;

}


.description {
  line-break: auto;
}
tr {
  height: 10px;
}
tr td {
  height: auto !important;
}

.campaignDetails {  
  background-color: #090d251a;
  width: 85%;
  display: flex;
  flex-direction: row;
}

.creators_table {
  @apply flex-1 justify-evenly w-5/6;
}

.video_detail {
  display: flex;
  flex-direction: column
}

.video_detail * {
}

.order_video_section {
  width: 110%;
}
.order_video_section iframe {
  width: 100%;
  height: 90%;
}

.order_video_section h3 {
  margin: 0px;
  padding: 3px;
}

.order_video_section p {
  text-align: start;
  padding: 0px;
  color: #2b3674;
  align-self: flex-start;
  margin: 3px;
}


.hoverCard-payment{
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
    /* add a shadow that is right below not in the side */ 
    box-shadow: 0px 0px 5px 0px rgba(130, 126, 126, 0.75);
    /* box-shadow: 0px 0px 10px 0px #2B3674; */
    margin-bottom: 10px;
    cursor: pointer;
    width: 400px;
    height: 200px;
    margin: 20px;
}

.hoverCard-payment:hover {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.hoverCard-payment h1{
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin: 0px;
}

.hoverCard-payment p{ 
    font-size: 14px;
    font-weight: 400;
    color: #000;
    margin: 0px;
}

.hoverCard-payment h3{
    font-size: 48px;
    font-weight: 600;
    color: #000;
    margin: 20px;
    text-align: center;
    vertical-align: middle;

}

.uploadButton{
    font-size: 46px;
    font-weight: 600;
    color: #000;
    margin: 20px;
    text-align: center;
    vertical-align: middle;
   
}
.progressBar{
    width: 82%;
    height: 60px;
    background-color: #2B3674;
    border-radius: 10px;
    margin: 10px 0px;
    align-self: center;
    position: relative;
    display: flex;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    align-items: center;
    color: #fff;

}

.progressBar h3{
    font-size: 20px;
    font-weight: 600;
    color: white;
    margin: 0px;
}



.progressBarWrapper{
    width: 83%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}




.inner-container {
  width: 100%;
  background: #090d25;
  height: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.circle {
  width: 65px;
  height: 65px;
  border-radius: 35px;
  margin: 5px;
  background: #090d25;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 3px 4px gray;

}

.circle p {
  color: #090d25;
  margin-top: 0;
  font-size: 10px;
}

.highlighted-circle {
  width: 85px;
  min-height: 85px;
  border-radius: 45px;
  margin: 5px;
  background: #090d25;
  border: 5px solid rgb(99, 94, 143);
  /* border: 7px soldisplay: flex; margin-bottom: 10px; width: 100%; flex-flow: column wrap; margin-left: 50px;id rgb(172, 26, 26); */

  box-shadow: 2px 3px 4px gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerBoxer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90px;
    height:150px;
    margin-bottom: -80px;
    background-color: transparent;
}

.headerBoxer-highlighted{
  margin-bottom: -16px;
}
.inner-container-used{
  background-color: rgb(99, 94, 143);
  height: 3px;
  width: 14%;
  position: absolute;
  z-index: 0;
}



.campaignList-header{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  height: 50px;
  background-color: transparent;
  margin: 20px;
  border: 1px solid white;
  padding: 10px;
  border-radius: 5px;;
}
.campaignList-header .option{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
  margin-bottom: 10px;
  color: hsl(229, 58%, 29%);
}

.campaignList-header  .option :hover {
  background-color: #ffffff !important;
  cursor: pointer;
}
.campaignList-header .option-selected{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
  margin-bottom: 10px;
  color: hsl(229, 58%, 29%);
  background-color: #090D25;
  color: white;
}