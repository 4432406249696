.FAQ{
    width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center !important;
    margin-bottom: 50px !important;
  
  }
  .FAQheader{
    font-family: "Railway" !important;
    font-size: 36px;
    font-weight: 700;
    color:#6968ff;
  
  }